.Container {
  padding: 0px;
  
}

.headerImg {
  background-image: url(../../img/HawkinsGroup_ConstructionPhotos_DRONE-0056.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 0;
  height: 90vh;
  box-shadow: 0 0 0.5rem 0.3rem rgba(255, 255, 255, 0.548);
  border: 10px ridge #333;
}
.headlineBox {
  padding: 5px;
  padding-top: 80px;
  align-content: cent;
}
.headline {
  vertical-align: center;
  padding: 5px;

  background-color: rgba(55, 55, 55, 0.8);
}

/**BOuncing arrow***/

.downArrow {
  position: center;
  bottom: 100%;
  left: 100%;
}
.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
/**end arrow bounce**/

p {
  color: rgb(255, 255, 255);
}

h1 {
  color: azure;
}
h3 {
  color: azure;
}

i {
  color: rgba(255, 197, 0, 0.548);
}

/** Footer styles */


.card {
  margin: 10px;
}

/* .Container {
  background-image: url(../../img/HawkinsGroup_ConstructionPhotos_DRONE-0055.jpg);
  background-size: cover;
  background-position: center;
}
*/
.darkoverlay {
  background-color: rgba(255, 255, 255, 0.5);

  z-index: 3;
  margin: 20px;
  border: 10px ridge #333;
  box-shadow: 0 0 0.5rem 0.3rem rgba(255, 255, 255, 0.548);
}
